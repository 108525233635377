<template>
  <div :style="'background-image: url(' + backgroundImage + '); background-size: cover; background-repeat: no-repeat;'">
    <b-container fluid>
      <b-row align-v="center" align-h="center" style="height: 100vh">
        <b-col sm="6" md="4" align-v="center">
          <b-card align="center" align-v="center" id="at" style="background-color: rgba(54, 138, 155, 0.8);margin-top: 50px;border-radius: 20px;box-shadow: 5px 5px 10px #5abcae;">
            <div>
              <b-row>
                <b-col cols="2"> </b-col>
                <b-col cols="8">
                  <h4 style="color: white;">WisdomEra</h4>
                </b-col>
                <b-col cols="2">
                  <template v-if="d_showLoginButtonClicked"><img src="../../static/img/gif/right-left.gif" class="img-rounded img-responsive" style="width: 20px; height: 20px;" /></template>
                </b-col>
              </b-row>
            </div>
            <b-card style="margin-top: 0px;background-color: rgba(255, 255, 255, 0.7);border-radius: 15px;">
              <h5 style="color: black;">Şifre Sıfırlama</h5>
              <b-form @submit.prevent="submit">
                <b-form-input type="text" class="form-control-warning" id="email" key="email" :formatter="f_toLowerCase(credentials.email, 'email')" v-model="credentials.email" :state="f_emailRegexCheck(credentials.email)"></b-form-input>
                <b-form-valid-feedback></b-form-valid-feedback>
                <b-form-invalid-feedback>Lütfen düzgün bir email giriniz</b-form-invalid-feedback>
                <div slot="footer">
                  <b-button :disabled="d_formCheck ? false : true" type="submit" size="sm" style="background-color: #368a9b;width: 200px;color: #c1e4e1;"><i class="fa fa-question" style="color: #f87574;"></i>&nbsp;şifre gönder
                  </b-button><br>
                  <b-button @click="f_goToLogin()" size="sm" style="background-color: rgb(54, 138, 155);width: 200px;color: #c1e4e1;"><i class="fa fa-sign-in" style="color: #45dc45;"></i>&nbsp;giriş ekranı
                    </b-button>
                </div>
              </b-form>
            </b-card>
            <span style="color:white; text-align: center; display: inline-block; margin-bottom: 10px; margin-top: 10px;">A New Era is Beginning by WisdomEra</span>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import auth from '@/auth';
import wdm16 from '@/wdm16';
import store from '@/store';
import UserService from '@/services/users';
import { WisdomRegex } from '@/services/public/functions';
import { mapGetters } from 'vuex';
export default {
  name: 'ResetPassword',
  computed: mapGetters({
    StoreLoading: 'loading'
  }),
  data () {
    return {
      wdm16: wdm16.wdm16,
      backgroundImage: require('../../static/img/LoginBackground.png'),
      credentials: {
        email: ''
      },
      store: store,
      d_formCheck: false,
      d_showLoginButtonClicked: false
    };
  },
  mounted: function () {},
  methods: {
    f_goToLogin: function () {
      this.$router.push('/login');
    },
    f_emailRegexCheck: function (email) {
      let status = WisdomRegex.email(email).status;
      this.d_formCheck = status;
      return status;
    },
    f_toLowerCase: function (value, param) {
      this.credentials[param] = value.toLowerCase()
    },
    submit () {
      this.d_showLoginButtonClicked = true;
      UserService.reset_password(this.credentials)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_promoCodeList = resp.data.result;
            this.d_showLoginButtonClicked = false;
            alert('Email adresine ait bir hesap bulunursa email gonderilecektir');
            this.$router.push('/login');
          } else {
            alert(resp.data.message);
            return;
          }
        });
    }
  },
  watch: {}
}

</script>

<style type="text/css">
canvas {
  background-color: #000;
}

</style>

